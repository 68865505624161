<template>
  <div class="app-container historyDetail" v-loading="loading">
    <el-form :inline="true">
      <el-form-item>
        <el-button size="small" type="primary" @click="back">返回</el-button>
      </el-form-item>
    </el-form>
    <div class="header_detail" style="margin-top: 20px">
      <div class="header_detail_itemList">
        <div>交易编号：{{ historyData.paymentOrderCode }}</div>
        <div>说明：{{ historyData.nickName || '--' }}在{{ parseTime(historyData.paymentTime) }}支付了订单</div>
        <div>
          变更金额：{{ $route.query.isIncome == 1 ? '+￥' + historyData.realAmount : '-￥' + historyData.realAmount }}
        </div>
        <div>剩余可用信用额度：￥{{ historyData.remainingAmount }}</div>
        <div>支付时间：{{ parseTime(historyData.paymentTime) }}</div>
      </div>
    </div>

    <div class="change">
      <div :class="['changeSelect', changeType === 'detail' && 'active']" @click="change('detail')">订单详情</div>
      <div :class="['changeSelect', changeType === 'statistics' && 'active']" @click="change('statistics')">
        款式统计
      </div>
    </div>

    <div v-if="changeType === 'detail'" class="detail-container" style="margin-top: 20px">
      <!-- 总计 -->
      <div class="total">
        <div>总计：￥{{ historyData.totalAmount }}</div>
        <div>优惠金额：￥{{ historyData.couponAmount }}</div>
        <div>实付款：￥{{ historyData.realAmount }}</div>
      </div>
      <!-- 订单列表 -->
      <div class="orderList" v-for="(v, i) in orderList" :key="i">
        <div class="orderList-Left">
          <div>订单号：{{ v.orderCode }}</div>
          <div>物流方式：{{ v.expressCompanyName }}</div>
          <div>运单号：{{ v.expressWaybillCode }}</div>
          <div>收件人：{{ v.consigneeName }}</div>
          <div>收件人国家：{{ v.consigneeCountryCode }}</div>
          <div>收件人地址：{{ v.consigneeDetailAddress }}</div>
        </div>
        <div class="orderList-right">
          <div>货款总计：{{ v.internalTotalPrice }}</div>
          <div>运费总计：{{ v.templateFreight }}</div>
          <div>订单应付金额：{{ $math.add(v.internalTotalPrice, v.templateFreight) }}</div>
          <div>订单优惠金额：{{ v.couponAmount }}</div>
          <div>订单实付金额：{{ $math.add(v.realInternalTotalPrice, v.realTemplateFreight) }}</div>
        </div>
      </div>
    </div>

    <div v-if="changeType === 'statistics'" class="statistics-container" style="margin-top: 20px">
      <div class="styleStatistics">
        <div class="styleStatistics_product">产品总计：{{ styleData.totalCount }}</div>
        <div>货款总额：{{ styleData.totalAmount }}</div>
      </div>
      <CommonTable :selection="false" :cols="cols" :infoData="tableData"> </CommonTable>
    </div>
  </div>
</template>

<script>
import { styleCols as cols } from './cols'
import { parseTime } from '@/utils'
// import { initDataMixin } from '@/mixins'
import { getDetails } from '@/api/balanceApi'
import { styleStatistical, orderList, getBillInfo } from '@/api/financial/userCredit'

export default {
  // mixins: [initDataMixin],
  data() {
    return {
      loading: false,
      cols,
      tableData: [],
      changeType: 'detail',
      historyData: {},
      styleData: {},
      orderList: []
      // url: '/externaladmin/orderService/externalOrder/orderList',
      // query: {
      //   id: Number(this.$route.query.id)
      // },
      // currentPage: 1,
      // pageSize: ,
      // afterData: []
    }
  },
  methods: {
    parseTime,
    change(data) {
      this.changeType = data
    },
    back() {
      this.$router.go(-1)
    }
  },
  computed: {
    type() {
      let cur
      if (!this.$route.query.tradeType) return
      let { tradeType } = this.$route.query
      tradeType = Number(tradeType)
      console.log('我被', tradeType)
      switch (tradeType) {
        case 1:
          cur = '充值'
          break
        case 2:
          cur = '提现'
          break
        case 3:
          cur = '订单支付'
          break
        case 4:
          cur = '退款'
          break
        case 5:
          cur = '手工调整余额'
          break
        case 6:
          cur = '提现审核拒绝退款'
          break
      }
      return cur
    },
    payType() {
      return (type) => {
        try {
          if (!type) return
          let cur
          switch (type) {
            case 1:
              cur = '支付宝'
              break
            case 2:
              cur = '微信'
              break
            case 3:
              cur = '银行卡'
              break
            case 4:
              cur = '余额付款'
              break
          }
          return cur
        } catch (error) {}
      }
    },
    currentState() {
      return (state) => {
        let cur
        switch (state) {
          case 0:
            cur = '未支付'
            break
          case 1:
            cur = '已支付'
            break
          case 2:
            cur = '关闭/取消'
            break
        }
        return cur
      }
    }
  },
  async created() {
    const id = Number(this.$route.query.id)
    this.loading = true
    try {
      const { code, detail } = await getBillInfo({ id })
      if (code != 0) return
      this.historyData = detail
    } catch (error) {}
    try {
      const { code, detail } = await orderList({
        isReturnRelatedData: 1,
        paymentOrderId: id,
        page: { pageIndex: 1, pageSize: 0 }
      })
      if (code != 0) return
      this.orderList = detail
    } catch (error) {}
    try {
      const { code, detail } = await styleStatistical({ id })
      if (code != 0) return
      this.styleData = detail || {}
      this.tableData = detail.styleStatisticalList
    } catch (error) {}
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.historyDetail {
  font-size: 14px;
}
.app-container {
  padding: 20px 20px;
  .header_detail {
    border: 1px solid #ccc;
    padding: 10px;
    .header_detail_itemList {
      div {
        margin-bottom: 10px;
      }
    }
  }
  // 订单详情
  .detail-container {
    border: 1px solid #ccc;
    padding: 10px;
    //  总计
    .total {
      display: flex;
      padding: 5px;
      div {
        margin-right: 10px;
      }
    }
    //  订单列表
    .orderList {
      border: 1px solid #ccc;
      padding: 10px 10px 0 10px;
      display: flex;
      margin-bottom: 10px;
      .orderList-Left {
        width: 400px;
        div {
          margin-bottom: 10px;
        }
      }
      .orderList-right {
        width: 400px;
        div {
          margin-bottom: 10px;
        }
      }
    }
  }
  .statistics-container {
    border: 1px solid #ccc;
    padding: 10px;
  }
  .change {
    margin-top: 20px;
    display: flex;
    width: 150px;
    justify-content: space-between;
    .changeSelect {
      cursor: pointer;
    }
    .active {
      color: #6067e2;
    }
  }
  .styleStatistics {
    display: flex;
    margin: 10px 10px 20px 10px;
    .styleStatistics_product {
      margin-right: 20px;
    }
  }
}
</style>
