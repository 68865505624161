import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//结账日期设置
export function updateSettlementDay(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/systemConfig/updateSettlementDay',
    method: 'post',
    data
  })
}
//获取结账日期
export function getLastUpdateDay(data = {}, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/systemConfig/getLastUpdateDay',
    method: 'post',
    data
  })
}

//更新启用赊账
export function updateEnabledCredit(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/updateEnabledCredit',
    method: 'post',
    data
  })
}
// 获取用户信用额度信息
export function getUserById(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/getUserById',
    method: 'post',
    data
  })
}

// 更新总信用额度
export function updateTotalCreditAmount(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/financeService/paymentAccount/updateTotalCreditAmount',
    method: 'post',
    data
  })
}

// 根据支付订单统计款式
export function styleStatistical(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/orderItem/styleStatistical',
    method: 'post',
    data
  })
}
// 订单详情列表
export function orderList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/externalOrder/orderList',
    method: 'post',
    data
  })
}
//
// 订单详情
export function getBillInfo(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/financeService/paymentOrder/getBillInfo',
    method: 'post',
    data
  })
}

// 月结账单导出
export function exportMonthBill(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/financeService/creditMonthBill/exportMonthBill',
    method: 'post',
    responseType: 'blob',
    data
  })
}
