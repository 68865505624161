import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//余额增减
export function updateBalance(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/financeService/paymentAccount/updateAccountAmount',
    method: 'post',
    data
  })
}

//获取详情
export function getDetails(data) {
  return request({
    url: '/externaladmin/financeService/paymentAccount/accountUpdateDetails',
    method: 'post',
    data
  })
}

//获取当前用户是否有设置过交易密码
export function passwordSetted(data) {
  return request({
    url: '/externaladmin/authService/user/isApproveConfirmPassword',
    method: 'post',
    data
  })
}

//导出
export function rechargeRecordExport(data) {
  return request({
    url: '/externaladmin/financeService/rechargeRecord/export',
    method: 'post',
    responseType:'blob',
    data
  })
}

// 导出2 

export function backgroundManualAdjustment(data) {
  return request({
    url: '/externaladmin/financeService/backgroundManualAdjustment/export',
    method: 'post',
    responseType:'blob',
    data
  })
}
